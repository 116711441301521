<template>
  <div ref="video" class=" mr-3">
    <div class="content-left">
      <iframe style='width: 600px;height: 338px' frameborder='no' allowfullscreen mozallowfullscreen webkitallowfullscreen src='http://go.plvideo.cn/front/video/preview?vid=dc5e7b954883fc2856bb38ee13ce6ca9_d'></iframe>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
export default {
  props: {
    videoLeft: String,
  },
  data() {
    return {
      isPlay: false,
      player: null,
      listHight: null,
    };
  },

  mounted() {
    let fullHeight = document.documentElement.clientHeight - 90;
    // this.$refs.video.style.height = `${fullHeight-400}px`;
    const options = {
      autoPlay: false,
    };
    this.player = videojs(
      this.$refs.videoPlayer,
      options,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );
    console.log("播放还是暂停", this.player);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose(); //销毁
    }
  },
  methods: {
    suspend() {
      console.log("点击播放", this.player.paused())
      var vide = this.player;
      if (vide.paused()) {
        this.isPlay = true;
        vide.play();
      } else if (vide.play()) {
        vide.pause();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.isShow {
  display: none !important;
}
.content-left {
  flex: 1;
  position: relative;
  .video-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
  }
  .video-button {
    z-index: 10;
    font-size: 3em;
    line-height: 1.5em;
    height: 1.5em;
    width: 1.5em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: red;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 50%;
    transition: all 0.4s;
    .caret {
      position: absolute;
      top: 28%;
      left: 40%;
      width: 0;
      height: 0;
      border-left: 20px solid;
      border-bottom: 15px solid transparent;
      border-top: 15px solid transparent;
      color: #fff;
    }
  }
}
</style>