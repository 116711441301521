<template>
  <div class="container-wrap ">
    <img  src="@/static/image/about-3-bg.png" class=" process-img" alt="">
    <common-title title="发展历程" english="Development history"></common-title>
    <div
      class="container-process container d-flex flex-column"
    >
      <div class="swiper-pagination" slot="pagination"></div>
      <div>
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in lists" :key="index">
            <div
              class="list-item"
              v-for="(item2, index2) in lists[index].events"
              :key="index2"
            >
              <div class="historyTime">
                {{ item2.historyTime | filterTime }}
              </div>
              <div class="event">
                {{ item2.event }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "@/components/index/common-title.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import baseUrl from "@/utils/request.js";
export default {
  filters: {
    filterTime(val) {
      return val.split(".")[


        1]+'月';
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    commonTitle,
  },
  data() {
    return {
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/about-4-1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplayDisableOnInteraction: false,
        disableOnInteraction: true,
        observer: true,
        observeParents: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        paginationBulletRender: (swiper, index, className) => {
          // console.log("ddd", this.lists);
          // let year = this.lists[index].year;
          // console.log("yearyearyearyear",year);
          return (
            '<span class="' +
            className +
            '"><span class="spanSame span' +
            (index + 1) +
            '">' +
            this.lists[index].year +
            "</span></span>"
          );
        },
      },
      lists: [
        {
          year: "",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper;
    },
  },
  mounted() {
    // this.swiperOption.pagination.renderBullet = '<span>' + 1 + '</span>';

    this.getHistory();
  },
  methods: {
    getHistory() {
      this.$axios
        .get(`${baseUrl.BASE_URL}/document/develop-history/list`)
        .then((res) => {
          console.log("获取成功", res.data);
          let result = res.data.data;
          let temp = _.groupBy(result, (item) => {
            return item.historyTime.substring(0, 4) + "年";
          });
          let keys = Object.keys(temp);
          this.lists = [];
          for (let i = 0; i < keys.length; i++) {
            let obj = {};
            obj.year = keys[i];
            obj.events = temp[keys[i]];
            this.lists.push(obj);
          }
          console.log("list", this.lists);
          let year = this.lists[0].year;
          console.log("yearyearyearyear", year);
        })
        .catch((err) => {
          console.log("获取失败", err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.process-img{
  position:absolute;
  right: 0;
  bottom: 0;
width: 35%;
}
.container-wrap{
  background-color:#11163c;
}
// 点击按钮
.swiper-pagination {
  position: relative !important;
  color: #6f76b0;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: bold;

}
/deep/ .swiper-pagination-bullets{
  border-bottom: 0.5px solid #6f76b0;
}
/deep/ .swiper-pagination-bullet {
  width: 100px;
  // padding:0px 80px;
  height: 50px;
  line-height:50px;
  text-align: center;
  background-color: transparent;
  opacity: 1;

  border-radius: 0 !important;
}
/deep/ .swiper-pagination-bullet-active{
  background-color: transparent !important;
  font-size: 30px !important;
  color: #ff4484;
  font-weight: bold !important;
  // border-bottom:2px solid #ff4484 !important;
}
.swiper-container {
  // background-color: #ff4484;
  width: 100%;
  color: #808cec;
  // height: 300px;
}

.container-process {
  height: 100%;
  // width: 400px;
  // background-color: #ffffff;
  .content-process-left {
    padding-top: 200px;
    .item-time {
      color: #808cec;
      font-size: 40px;
    }
  }
  .list-item {
    padding-top: 10px;
    .historyTime {
      font-size: 20px;
      font-weight: bold;
    }
    .event {
      color: #ffffff;
      text-indent: 2em;
      position: relative;
      &:before{
        position: absolute;
        content: "11";
        font-size: 0;
        width: 5px;
        height: 5px;
        left: 20px;
        top: 10px;
        background-color: #fff;
      }
    }
  }
}
</style>
