<template>
  <div class="container-introduce">
    <div class="container">
      <div
        class="container-1 pt-5 d-flex justify-content-center align-items-center"
      >
        <img
          class="w-25"
          :class="moveShow ? 'slide-in-left' : ''"
          src="@/static/image/about-1-1.png"
          alt=""
        />
        <div
          class="text-center"
          :class="moveShow ? 'tracking-in-expand-fwd-top' : ''"
        >
          <common-title
            title="企业介绍"
            english="Company Introduction"
          ></common-title>
          <div class="Introduction">
            <p>国家高新技术企业</p>
            <p>深圳市专精特新企业</p>
            <p>携手中科院深圳先进院建立“智能文档处理”联合实验室</p>
            <p>2022中国数字科技年度50强</p>
            <p>2022中国数字科技金融垂直领域的标杆品牌</p>
            <p>企业信用评价AAA级企业</p>
          </div>
        </div>
        <img
          class="w-25"
          :class="moveShow ? 'slide-in-right' : ''"
          src="@/static/image/about-1-2.png"
          alt=""
        />
      </div>
      <div
        class="container-2 pt-5 d-flex justify-content-center align-items-center"
      >
        <div :style="bg_image" :class="moveShow ? 'slide-in-left-txt' : ''">
          <p>
            中壬银兴成立于 2016 年，是一家以大数据、云计算、区块链技术为基础的金融科技型国家高新技术企业、深圳市专精特新企业，也是国内领先的金融科技应用平台、金融科技服务模式创新实践平台、金融科技领域杰出服务机构、中国数字科技年度 50 强企业、数字科技金融垂直领域的标杆品牌。
          </p>
          <p>
            中壬银兴发明专利PVE算法是目前在B端信贷风控场景中较为前沿的底层算力，以PVE 专利算法为核心孵化的 PVE 债权评测系统已成为包含上市公司背景国资背景在内等多家金融机构风控技术供应商。2021 年，中壬银兴成立蓝莓企服作为 PVE 系统的科技成果转化平台面向企业提供服务，为超过 20000 家中小微企业提供企业安全测评服务。目前 PVE 债权评测系统服务客户遍布粤港澳大湾区评测次数超 125 万次，有效帮助客户识别与防范风险，提高业务决策效率。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "@/components/index/common-title.vue";
export default {
  components: {
    commonTitle,
  },
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/about-1-3.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.Introduction p {
  font-size: 22px;
  color: #6f76b0;
}
.container-2 {
  p {
    line-height: 1.8;
    color: #6f76b0;
    font-size: 18px;
    text-indent: 2em;
  }
}

.container-introduce {
  background-color: #f6f3ff;
  height: 100%;
}

.tracking-in-expand-fwd-top {
  animation: tracking-in-expand-fwd-top 1s 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.slide-in-left-txt {
  animation: slide-in-left 0.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-left {
  animation: slide-in-left 1.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-in-right {
  animation: slide-in-right 1.5s 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

// 左进入
@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
// 右进入
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
