<template>
  <div class="container-culture" :style="bg_image">
    <div>
      <common-title title="企业文化" english="Enterprise"></common-title>
    </div>
    <!-- 視頻 -->
    <div class="d-flex align-items-center justify-content-center flex-column">
      <div class="d-flex align-items-center justify-content-center">
        <div class="mr-5 text-center">
          <!--          <h4>员工介绍视频</h4>-->
          <iframe style='width: 600px;height: 338px' frameborder='no' allowfullscreen mozallowfullscreen webkitallowfullscreen src='//go.plvideo.cn/front/video/preview?vid=dc5e7b9548a1094c6ab2a146d375266e_d'></iframe>
        </div>
        <div class="ml-5 text-center">
          <!--          <h4>企业介绍视频</h4>-->
          <iframe style='width: 600px;height: 338px' frameborder='no' allowfullscreen mozallowfullscreen webkitallowfullscreen src='//go.plvideo.cn/front/video/preview?vid=dc5e7b954883fc2856bb38ee13ce6ca9_d'></iframe>
        </div>
      </div>
      <!-- 圖片 -->
      <div class="mt-5" style="width: 1200px" :class="moveShow ? 'tracking-in-expand-fwd-bottom' : ''">
        <swiper :options="swiperOption" ref="mySwiper" v-viewer>
          <swiper-slide v-for="(items, index) in cultureImgs" :key="index">
            <img class="w-100" style="height: 130px; cursor: pointer" :src="items.src" alt="" />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" :style="arrow_left" slot="button-prev" @click="prev"></div>
        <div class="swiper-button-next" :style="arrow_rigth" slot="button-next" @click="next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";
Vue.use(Viewer);
import commonTitle from "@/components/index/common-title.vue";
import cultureVideoLeft from "@/components/about/culture-video-left.vue";
import cultureVideoRight from "@/components/about/culture-video-right.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  components: {
    commonTitle,
    cultureVideoLeft,
    cultureVideoRight,
    Swiper,
    SwiperSlide,
    Viewer,
  },
  data() {
    return {
      cultureImgs: [
        {
          src: require("@/static/image/about-3-2.png"),
        },
        // {
        //   src: require("@/static/image/honor_3.jpg"),
        // },


        {
          src: require("@/static/image/about-2021-1.png"),
        },
        {
          src: require("@/static/image/about-2021-2.png"),
        },
        {
          src: require("@/static/image/about-2021-3.png"),
        },
        {
          src: require("@/static/image/about-2021-4.png"),
        },
        {
          src: require("@/static/image/about-3-3.png"),
        },
        {
          src: require("@/static/image/about-3-4.png"),
        },
      ],
      swiperOption: {
        loop: true,
        speed: 500,
        slidesPerView: 5,
        spaceBetween: 20,
        centeredSlides: true,
        watchSlidesProgress: true,
        pagination: ".swiper-pagination-process",
        paginationClickable: true,

        prevEl: ".swiper-button-prev", //上一张
        nextEl: ".swiper-button-next", //下一张
      },
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/about-3-1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      arrow_rigth: {
        backgroundImage: "url(" + require("@/static/image/right-arr.png") + ")",
        backgroundRepeat: "no-repeat",
      },
      arrow_left: {
        backgroundImage: "url(" + require("@/static/image/left-arr.png") + ")",
        backgroundRepeat: "no-repeat",
      },
    };
  },
  mounted() {
    Viewer.setDefaults({
      show: function (e) {
      },
      hide: function (e) {
      },
    });
  },
  methods: {
    handle() { },
    prev() {
      console.log("");
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
  },
};
</script>

<style lang="less" scoped>
.swiper-button-prev {
  left: -40px !important;
  border: 10px solid transparent !important;
}
.swiper-button-next {
  right: -40px !important;
  border: 10px solid transparent !important;
}
#vide {
  object-fit: fill !important;
}
.container-culture {
  height: 100%;
  .itemImgs {
    img {
      width: 100% !important;
      margin-right: 10px;
    }
  }
  .tracking-in-expand-fwd-bottom {
    animation: tracking-in-expand-fwd-bottom 1.5s 0s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  @keyframes tracking-in-expand-fwd-bottom {
    0% {
      letter-spacing: -0.5em;
      transform: translateZ(-700px) translateY(500px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
}
</style>
