<template>
  <div class="container-news">
    <common-title title="新闻资讯" english="Enterprise"></common-title>
    <div class="d-flex flex-wrap news-contents container">
      <div
        class="news-item"
        v-for="(item, index) in content"
        :key="index"
        @click="newDetail(item.newsId)"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="content-img mr-2">
            <img class="" :src="item.imageUrl" alt="" />
          </div>

          <div class="content-txt">
            <div class="title ellipsis-num-2">
              {{ item.title }}
            </div>
            <div class="publishTime">
              {{ item.publishTime }}
            </div>
            <div class="ellipsis-num-2 summary">
              {{ item.summary }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <!-- 分页 -->
      <div class="row page" v-if="content.length > 0">
        <nav aria-label="Page navigation example ">
          <ul class="pagination">
            <li class="page-item">
              <span
                class="page-link hover-hand"
                aria-label="Previous"
                aria-disabled="true"
                @click="Previous()"
                :class="page === 1 ? 'pageDisabled' : ''"
              >
                <span aria-hidden="true">&laquo;</span>
              </span>
            </li>
            <li
              :class="{'hover-hand':i !=='...','page-item':i !=='...'}"
              v-for="i in pageArr"
              :key="i"
              @click="ChooserPage(i)"
            >
              <span
                class="page-link"
                :class="i === page && i !== '...' ? 'pageActive' : ''"
              >{{ i }}</span
              >
            </li>
            <li class="page-item">
              <span
                class="page-link hover-hand"
                aria-label="Next"
                @click="Next()"
                :class="page === allPage ? 'pageDisabled' : ''"
              >
                <span aria-hidden="true">&raquo;</span>
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
  import commonTitle from "@/components/index/common-title.vue";
  import baseUrl from "@/utils/request.js";

  export default {
    components: {
      commonTitle,
    },
    data() {
      return {
        content: [],
        page: 1,
        size: 6,
        allPage: 1,
        pageIndex: 1,
        total: 0,
        isShow: false,
      };
    },

    computed: {
      pageArr() {
        let count = 5
        let arr = [];
        if (this.allPage <= count) {
          for (let i = 1; i <= this.allPage; i++) {
            arr.push(i)
          }
        } else {
          if (this.page <= 3) {
            for (let i = 1; i <= count; i++) {
              arr.push(i)
            }
            arr.push("...")
            arr.push(this.allPage)
          } else if (this.page >=  this.allPage - 2) {
            arr.push(1)
            arr.push("...")
            for (let i = this.allPage - count + 1; i <= this.allPage; i++) {
              arr.push(i)
            }
          } else {
            arr.push(1)
            arr.push("...")
            for (let i = this.page - 2; i <= this.page + 2; i++) {
              arr.push(i)
            }
            arr.push("...")
            arr.push(this.allPage)
          }
        }
        return arr
      }
    },

    mounted() {
      this.getNewList();
      this.allPage = 10;
    },
    methods: {
      // 文章详情
      newDetail(id) {
        console.log("ididi", id);
        this.$router.push({ name: "newdetail", query: { id: id } });
      },
      // 文章列表
      getNewList() {
        // console.log("接口地址",`${baseUrl.BASE_URL}/v1/document/news/list`)
        let data = {};
        data.page = this.page;
        data.size = this.size;
        data.newsTypeCodes = ["DC002"];
        this.$axios
          .post(`${baseUrl.BASE_URL}/document/news/list`, data)
          .then((res) => {
            this.$loading.show();
            // console.log("获取成功", res);
            this.content = res.data.data;
            this.total = res.data.total;
            this.allPage = Math.ceil(this.total / this.size);
            this.$loading.hide();
          })
          .catch((err) => {
            this.$loading.hide();
            console.log("获取失败", err);
          });
      },
      // 选择页数
      ChooserPage(page) {
        if (page !== "...") {
          this.page = page;
          this.getNewList();
        }
      },
      Previous() {
        if (this.page !== 1) {
          this.page--;
          this.ChooserPage(this.page);
          this.getNewList();
        }
        return false;
      },
      Next() {
        if (this.page !== this.allPage) {
          this.page++;
          this.ChooserPage(this.page);
          this.getNewList();
        }
        return false;
      },
    },
  };
</script>

<style lang="less" scoped>
  .hover-hand {
    cursor: pointer;
  }

  .page {
    display: flex;
    justify-content: center;
  }

  .btn-hover:hover {
    background-color: #eee;
    // border-color: ;
    border-color: #007bff !important;
    color: #007bff;
  }

  ul > li {
    margin-right: 1rem;
  }

  .page-link {
    color: #999;
  }

  .pageActive {
    color: #fff !important;
    background-color: #6f76b0;
  }

  .pageDisabled {
    cursor: not-allowed;
  }

  .container-news {
    background-color: #f6f3ff;
    height: 100%;

    .news-contents {
      .news-item {
        cursor: pointer;
        border: 1px solid #e5e5e5;
        padding: 10px;
        width: 46%;
        margin-right: 40px;
        margin-bottom: 40px;
        transition: border 0.8s;

        .content-img {
          height: 115px;
          width: 30%;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            transition: 1s transform;
          }
        }

        &:hover {
          border: 1px solid #808cec;
        }

        &:hover img {
          transform: scale(1.2);
        }

        // height: 200px;
        .content-txt {
          flex: 1;
          padding-left: 5px;

          .title {
            font-size: 18px;
          }

          .publishTime {
            font-size: 12px;
            padding: 5px 0px;
            color: #738690;
          }

          .summary {
            font-size: 14px;
            color: #738690;
          }
        }
      }
    }
  }

  .ellipsis-num-1 {
    display: -ms-box;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    -webkit-line-clamp: 1;
    white-space: normal !important;
    -ms-line-clamp: 1;
    white-space: normal !important;
    -moz-line-clamp: 1;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -moz-box-orient: vertical;
  }

  .ellipsis-num-2 {
    display: -ms-box;
    display: -moz-box;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal !important;
    -webkit-line-clamp: 2;
    white-space: normal !important;
    -ms-line-clamp: 2;
    white-space: normal !important;
    -moz-line-clamp: 2;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -moz-box-orient: vertical;
  }
</style>
