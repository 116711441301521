<template>
  <div class="container-idea" :style="bg_image">
    <div
      class="row d-flex justify-content-center align-items-center flex-column"
    >
      <common-title title="企业理念" english="Enterprise"></common-title>
      <div
        class="ideaItems d-flex justify-content-center align-items-center flex-column"
      >
        <div
          class="ideaItem text-center"
          style=""
          v-for="(item, index) in ideaItems"
          :key="index"
          :class="
            moveShow
              ? 'tracking-in-expand-fwd-bottom':'' "
        >
          <h3 class="text-white">{{ item.title }}</h3>
          <h5 class="text-white">
            {{ item.text }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonTitle from "@/components/index/common-title.vue";

export default {
  props: {
    moveShow: {
      type: Boolean,
    },
  },
  components: {
    commonTitle,
  },
  data() {
    return {
      ideaItems: [
        {
          title: "企业使命",
          text: "无限探索数字科技服务边界   为客户创造超出预期的价值",
        },
        {
          title: "企业愿景",
          text: "  深度激发数据价值   满足企业多维业态需求",
        },
        {
          title: "企业价值",
          text: "创新第一  勇谋并进  知行合一  坚韧不弃",
        },
      ],
      bg_image: {
        backgroundImage: "url(" + require("@/static/image/about-2-1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.container-idea {
  height: 100%;
  .ideaItems {
    .ideaItem {
      &:nth-child(1) {
        padding-top: 80px;
      }
      &:nth-child(2) {
        padding-top: 120px;
      }
      &:nth-child(3) {
        padding-top: 150px;
      }
    }
  }

  .tracking-in-expand-fwd-bottom {
    animation: tracking-in-expand-fwd-bottom 1s 0s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
  @keyframes tracking-in-expand-fwd-bottom {
    0% {
      letter-spacing: -0.5em;
      transform: translateZ(-700px) translateY(500px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
}
</style>