<template>
  <div class="content" :style="listHight" ref="content">
    <ul class="list" ref="list">
      <!-- 企业介绍 -->
      <li :style="listHight">
        <first-introduce :moveShow="index === 0"></first-introduce>
      </li>
      <!-- 企业理念 -->
      <li :style="listHight">
        <two-idea :moveShow="index === 1"> </two-idea>
      </li>
      <!-- 企业文化 -->
      <li :style="listHight">
        <there-culture :moveShow="index === 2"></there-culture>
      </li>
      <!-- 发展历史 -->
      <li :style="listHight">
        <four-process :moveShow="index === 3"></four-process>
      </li>
      <!-- 新闻资讯 -->
      <li :style="listHight">
        <five-news :moveShow="index === 4" ref="fiveNews"></five-news>
      </li>
    </ul>
  </div>
</template>
<script>
import firstIntroduce from "@/components/about/first-introduce.vue";
import twoIdea from "@/components/about/two-idea.vue";
import thereCulture from "@/components/about/there-culture.vue";
import fourProcess from "@/components/about/four-process.vue";
import fiveNews from "@/components/about/five-news.vue";
import minScene from "@/minixs/screen.js"
export default {
  components: {
    firstIntroduce,
    twoIdea,
    thereCulture,
    fourProcess,
    fiveNews,
  },
  mixins: [minScene],
  //页面加载完成,获取dom节点
  mounted() {
    let option = this.$route.params;
    this.move(option.index);
  },
};
</script>

<style lang="less">
ul,
li {
  margin: 0px;
  padding: 0px;
}
.content {
  overflow: hidden;
  position: relative;
  width: 100%;
  .list {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // background: pink;
    transition: 1s top;
    li {
      list-style: none;
      position: relative;
      background-position: 50% 50% !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}
</style>